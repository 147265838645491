<template>
  <div class="games">
    <v-card class="mt-3" v-if="loading">
      <v-row justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-card>

    <div v-else>
      <v-card v-if="!fromDialeticInstitution">
        <div class="top-card-background">
          <v-img
            class="institution-logo"
            v-if="hasInstitutionLogo"
            :src="srcLogoImage"
          >
          </v-img>
          <span class="top-card-text">{{ institutionName }}</span>
          <span class="top-card-text">{{ institutionCity }}</span>
        </div>
        <BreadCrumbs v-if="hasBreadCrumbs" :breadcrumbs="breadcrumbs" />
      </v-card>

      <v-card v-else>
        <div class="top-dialetic-card-background">
          <v-img class="institution-logo" width="80" :src="srcDialeticsLogo">
          </v-img>
          <span class="black--text dialetics-title">Dialéticos</span>
        </div>
      </v-card>

      <v-card class="mt-2" v-if="stateEntityInfo">
        <v-card-title> {{ stateEntityInfo.name }} </v-card-title>
        <v-card-text>
          <v-row>
            <v-col style="text-align: justify">
              <span>{{ stateEntityInfo.description }}</span>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            v-if="userData && userData.Group.group_code === 4 ? true : false"
          >
            <v-col class="d-flex align-end justify-end">
              <v-btn
                color="black"
                title="Editar texto"
                @click="editEntity(stateEntityInfo.id)"
                icon
              >
                <v-icon>mdi-script-text-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title> Meus debates </v-card-title>
        <v-card-text>
          <v-row v-if="isAdmin">
            <v-col>
              <v-btn small color="success" @click="redirectToNewGameScreen()">
                + Novo debate
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, i) in games" :key="i">
                  <v-expansion-panel-header>
                    {{ item.theme }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-btn @click="redirectToGameScreen(item.id)" small text>
                      <v-icon left>mdi-arrow-right-bold-box</v-icon>
                      Entrar</v-btn
                    >
                    <v-btn
                      v-if="isAdmin && !fromDialeticInstitution"
                      small
                      text
                      @click="openModeratorsDialog(item.id)"
                    >
                      <v-icon left>mdi-account-star</v-icon>
                      Moderadores</v-btn
                    >
                    <v-btn
                      v-if="isAdmin"
                      small
                      text
                      @click="redirectToUpdateGameScreen(item.id)"
                    >
                      <v-icon left>mdi-pencil</v-icon> Editar</v-btn
                    >
                    <v-btn
                      v-if="isAdmin"
                      small
                      text
                      @click="deleteGames(item.id)"
                    >
                      <v-icon left>mdi-delete</v-icon>
                      Excluir</v-btn
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>

        <v-dialog v-model="moderatorsDialog" persistent max-width="600">
          <GameModeratorsForm
            :mode="moderatorsDialogMode"
            :modal="true"
            :gameId="gameIdForUpdateModerators"
            @close-dialog="moderatorsDialog = false"
          />
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { baseUrl } from "@/config/config.json";
import { mapActions, mapState } from "vuex";
import dialeticsLogo from "@/assets/dialetic-phi-logo.png";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";
import GameModeratorsForm from "@/views/Games/GameModeratorsForm.vue";

export default {
  components: {
    GameModeratorsForm,
    BreadCrumbs,
  },
  props: {
    fromDialeticInstitution: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      moderatorsDialog: false,
      moderatorsDialogMode: "update",
      srcDialeticsLogo: dialeticsLogo,
      gameIdForUpdateModerators: null,
      institutionInfo: null,
      stateEntityInfo: null,
      breadcrumbs: null,
      moderators: [],
      games: [],
    };
  },
  methods: {
    ...mapActions("moderator", ["getModerators"]),
    async getRoomGames() {
      try {
        this.loading = true;

        const res = await this.$axios.get(
          `/salas/${this.roomId}/poder/${this.entityId}/jogos`
        );

        this.setGamesData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setGamesData(data) {
      const cpData = { ...data };

      this.games = cpData.games;
      this.stateEntityInfo = cpData.stateEntity;
      if (!this.fromDialeticInstitution)
        this.breadcrumbs.push({
          text: this.stateEntityInfo.name,
          href: "",
          disabled: true,
        });
    },
    async getRoomInfo() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/salas/${this.roomId}`);

        this.setRoomData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setRoomData(data) {
      const cpData = { ...data };

      this.breadcrumbs = [
        {
          text: cpData.breadcrumbs[1].text,
          href: "javascript:history.back()",
          disabled: false,
        },
      ];
      this.institutionInfo = cpData.institutionInfo;

      this.getRoomGames();
    },
    async openModeratorsDialog(gameId) {
      try {
        this.loading = true;

        await this.getModerators(gameId);
        this.gameIdForUpdateModerators = gameId;
        this.moderatorsDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async getDialeticEntityGames() {
      try {
        const res = await this.$axios.get(
          `/dialeticos/poder/${this.entityId}/jogos`
        );

        this.setGamesData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteGames(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Alerta de AÇÃO IRREVERSÍVEL",
          `Deseja realmente remover este debate do sistema? `
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/admin/jogos/${id}`;

        await this.$axios.delete(url);

        this.deleteGamesFromTable(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteGamesFromTable(id) {
      const index = this.games.findIndex((p) => p.id === id);

      this.games.splice(index, 1);
    },
    editEntity(id) {
      this.$router.push(`/admin/poderes/${id}/editar`);
    },
    redirectToGameScreen(gameId) {
      const route = this.fromDialeticInstitution
        ? `/jogo/${gameId}/dialeticos`
        : `/sala/${this.roomId}/jogo/${gameId}`;

      this.$router.push(route);
    },
    redirectToNewGameScreen() {
      const url = this.fromDialeticInstitution
        ? "/jogos/dialeticos/cadastrar"
        : `/salas/${this.roomId}/jogos/cadastrar/${this.entityId}`;

      this.$router.push(url);
    },
    redirectToUpdateGameScreen(id) {
      const url = this.fromDialeticInstitution
        ? `/jogos/${id}/dialeticos/editar`
        : `/salas/${this.roomId}/jogos/${id}/editar`;
      this.$router.push(url);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    roomId() {
      return this.$route.params.roomId;
    },
    entityId() {
      return this.$route.params.entityId;
    },
    institutionName() {
      return this.institutionInfo && this.institutionInfo.institution_name
        ? this.institutionInfo.institution_name
        : "";
    },
    institutionCity() {
      return this.institutionInfo && this.institutionInfo.city_address
        ? `${this.institutionInfo.city_address} - ${this.institutionInfo.state_address}`
        : "";
    },
    hasInstitutionLogo() {
      return this.institutionInfo && this.institutionInfo.logo ? true : false;
    },
    hasBreadCrumbs() {
      return this.breadcrumbs ? true : false;
    },
    srcLogoImage() {
      if (!this.institutionInfo || !this.institutionInfo.logo) return "/#";

      return `${baseUrl}/uploads/institutions/${this.institutionInfo.logo}`;
    },
    isAdmin() {
      if (this.fromDialeticInstitution)
        //se for administrador
        return this.userData.Group.group_code === 4 ? true : false;
      else return this.userData.Group.group_code === 1 ? false : true; //se não for aluno
    },
  },
  watch: {
    roomId(value) {
      if (!value) return;

      this.getRoomInfo();
    },
  },
  created() {
    if (this.fromDialeticInstitution) this.getDialeticEntityGames();
    else this.getRoomInfo();
  },
};
</script>

<style scoped>
.top-card-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #0e0d0d, #3f3f3f);
  min-height: 150px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.top-dialetic-card-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #bbbbbb, #3a3a3a);
  min-height: 150px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.institution-logo {
  max-width: 12%;
}

.top-card-text {
  color: #ffffff;
}

.dialetics-title {
  font-size: 1.5rem;
}
</style>
