<template>
  <div>
    <v-combobox
      @input="onSelect"
      :value="value"
      :items="users"
      :loading="loading"
      :search-input.sync="search"
      item-text="fullName"
      item-value="id"
      return-object
      placeholder="Busque aqui"
      :label="label"
      class="required"
      :hide-details="hideDetails"
      cache-items
      :clearable="!disabled && !readonly"
      :disabled="disabled"
      :readonly="readonly"
      ref="moderatorsAutocomplete"
    >
      <template v-slot:no-data>
        <div class="pa-3">Nenhum participante encontrado</div>
      </template>

      <template v-slot:item="data">
        {{ data.item.fullName }}
      </template>
    </v-combobox>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  props: ["value", "disabled", "readonly", "label", "hide-details", "roomId"],
  data() {
    return {
      loading: false,
      search: null,
      users: [],
    };
  },
  watch: {
    search(val) {
      if (this.loading || !val || val !== this.search) return;

      this.loadUsers(val);
    },
  },
  methods: {
    onSelect(evt) {
      if (evt) {
        this.$emit("input", evt);
        this.search = null;
      }
    },
    setUsers(data) {
      const copyOfData = { ...data };

      this.users = copyOfData.data.map((u) => {
        return { ...u, fullName: `${u.firstName} ${u.lastName}` };
      });
    },
    async loadUsers(search) {
      try {
        this.loading = true;

        const params = {
          pagination: "false",
          search,
        };

        const response = await this.$axios.get(
          `/admin/sala/${this.roomId}/participantes`,
          { params }
        );

        this.setUsers(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    clearAutocomplete() {
      this.search = null;
      this.$emit("input", null);
      this.$refs.moderatorsAutocomplete.cachedItems = [];
    },
  },
  computed: {},
};
</script>

<style></style>
