<template>
  <div>
    <v-card>
      <v-card-title> {{ formattedTitle }} </v-card-title>

      <v-card-text>
        <v-row class="mt-3">
          <v-col>
            <ModeratorsAutocomplete
              label="Buscar Moderador"
              v-model="userForm"
              :roomId="roomId"
            />
          </v-col>
        </v-row>

        <div class="selected-users mt-2" v-if="moderators && moderators.length">
          <v-row class="mb-2">
            <v-col>
              <h3>Moderadores da sala</h3>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="moderators"
            disable-pagination
            hide-default-footer
            :loading="loading"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                title="Deletar"
                @click="deleteUser(item.id)"
                icon
                color="error"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              small
              class="primary darken-1 mr-2"
              @click="save()"
              :loading="loading"
            >
              <v-icon left>mdi-content-save</v-icon> {{ saveButtonText }}
            </v-btn>
            <v-btn
              outlined
              small
              text
              @click="closeOrCancel()"
              :loading="loading"
            >
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeOrCancel()">Ok</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { mapMutations, mapActions, mapState } from "vuex";
import ModeratorsAutocomplete from "@/components/Users/ModeratorsAutocomplete.vue";

export default {
  components: {
    ModeratorsAutocomplete,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
    gameId: {
      type: Number,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("moderator", ["moderators"]),
    roomId() {
      return this.$route.params.id;
    },
    saveButtonText() {
      return this.mode === "insert" ? "Criar sala" : "Salvar alterações";
    },
    formattedTitle() {
      return this.title ? this.title : "Moderadores";
    },
  },
  data() {
    return {
      userForm: null,
      form: {},
      loading: false,
      successDialog: false,
      headers: [
        { text: "Nome", value: "fullName" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    ...mapMutations("moderator", ["setModerators"]),
    ...mapMutations("moderator", ["clearModerators"]),
    ...mapMutations("moderator", ["deleteModerator"]),
    ...mapActions("moderator", ["getModerators"]),
    validateForm() {
      const errors = [];

      if (!this.moderators.length) errors.push("Selecione ao menos um usuário");

      return errors;
    },
    validateToSelectNewUser(id) {
      const index = this.moderators.findIndex((p) => p.id === id);

      if (index != -1) {
        const error = ["Usuário já selecionado"];
        this.handleErrors(error);
        return false;
      }

      return true;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async update() {
      try {
        this.loading = true;

        let dataToSend = this.formatDataToSend(this.form);

        await this.$axios.put(
          `/admin/jogo/${this.gameId}/moderadores`,
          dataToSend
        );

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatDataToSend(data) {
      const cpData = { ...data };

      cpData.moderators = this.moderators.map((u) => {
        return u.id;
      });

      return cpData;
    },
    deleteUser(id) {
      this.deleteModerator(id);
    },
    closeOrCancel() {
      if (this.modal) {
        this.clearDialog();
        this.$emit("close-dialog");
      } else {
        this.initializeForm();
      }

      this.successDialog = false;
    },
    clearDialog() {
      this.userForm = null;
      this.form = {};
      this.clearModerators();
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
    handleSetModerators(array) {
      this.setModerators(array);
    },
    async initializeForm() {
      try {
        this.loading = true;

        await this.getModerators();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    userForm(value) {
      if (!value) return;

      const valid = this.validateToSelectNewUser(value.id);

      if (valid) this.handleSetModerators([...this.moderators, { ...value }]);
    },
  },
  mounted() {
    if (this.mode === "update" && !this.modal) this.initializeForm();
  },
};
</script>

<style scoped>
.row {
  margin: 0;
}
</style>
